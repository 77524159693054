import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout.js";
import PortableText from "../components/PortableText";
import { PageH1 } from "./../styledcomponents/layoutstyles";
import RSSIcon from "./../images/rss.svg";

const RssLink = styled.a`
  position: absolute;
  right: 0;
  opacity: 0.5;
  transition: 0.5s;
  & img {
    border-radius: 4px;
  }
  &:hover {
    opacity: 1;
  }
`;

const UpdateArticle = styled.article`
  display: flex;
  flex-direction: column;
  margin: 0 var(--outsidePadding) var(--outsidePadding);
  position: relative;
  font-family: var(--uiFont);
  & + article {
    margin-top: var(--outsidePadding);
    padding-top: 40px; /* var(--outsidePadding); */
    &:before {
      position: absolute;
      content: "★ ★ ★ ★ ★";
      text-align: center;
      font-size: 24px;
      color: var(--gold);
      top: -12px;
      left: 0;
      right: 0;
    }
  }
  & h2 {
    margin: 0;
    & span {
      margin-left: var(--outsidePadding);
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
      display: flex;
      flex-direction: column;
      & span {
        margin-left: 0;
        font-size: 18px;
      }
    }
  }
  & div {
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    line-height: 24px;
    & a {
      font-weight: bold;
    }
  }
`;

// TODO:  is this actually sorting by dates correctly?

const Updates = () => {
  const updateQuery = useStaticQuery(graphql`
    query {
      allSanityUpdate {
        edges {
          node {
            _id
            title
            _rawBody(resolveReferences: { maxDepth: 5 })
            publishedAt
          }
        }
      }
    }
  `);
  const updateList = updateQuery.allSanityUpdate.edges
    .map((x) => {
      return {
        slug: x.node.title
          .toLowerCase()
          .replace(/<[^>]*>?/gm, "")
          .replace(/!/gm, "")
          .replace(/'/gm, "")
          .replace(/\s+/g, "-")
          .slice(0, 96),

        title: x.node.title,
        date: x.node.publishedAt,
        body: x.node._rawBody,
      };
    })
    .sort((a, b) => (a.date > b.date ? -1 : 1));
  // console.log(updateList);
  return (
    <Layout title={`Ring On Deli: Updates`}>
      <PageH1>
        Updates
        <RssLink href="/rss.xml" target="_blank" rel="noopener noreferrer">
          <img src={RSSIcon} alt="Subscribe to the RSS feed!" />
        </RssLink>
      </PageH1>
      {updateList.map((update, index) => {
        const theDate = new Date(update.date);
        // console.log(update.date, theDate);
        const month = theDate.toLocaleString("default", { month: "long" });
        return (
          <UpdateArticle key={index}>
            <h2>
              <Link
                to={`/update/${update.slug}`}
                dangerouslySetInnerHTML={{ __html: update.title }}
              />
              <span className="italic unbold">{`${month} ${theDate.getDate()}, ${theDate.getUTCFullYear()}`}</span>
            </h2>
            <div>
              <PortableText blocks={update.body} />
            </div>
          </UpdateArticle>
        );
      })}
    </Layout>
  );
};

export default Updates;
